<template>
	<div>
		<p class="m-right-title">
		<span class="u-flex-1">课程讲义</span>
		<template v-if="1">  
	 <div v-if="downloading" style="color:#ddd;padding-right:30px ;" class="m-item-btn m-item-btn-extend">文件加载中：{{ downloadProgress }}%</div>
		</template>
		
		</p>
		<div class="m-list" v-loading="loading">
			<div class="m-item" v-for="(item,index) in list">
				<div class="m-item-img">
					<div class="m-item-tip">{{item.type == 1 ? '视频' :'文档'}}</div>
					<img :src="item.cover" />
				</div>
				<div class="m-item-con u-m-l-16">
					<p class="u-line-2 m-item-font1">{{item.name}}</p>
					<div class="u-flex">
						<div class="m-item-time">
							<img src="../../assets/img/common/icon-time4.png" />
							<span class="u-m-l-6">学时安排：{{item.period}}</span>
						</div>
					</div>
				</div>
				<div class="u-flex-col u-flex-1 u-col-bottom u-row-center">
					<div  class="m-item-btn m-item-btn-extend"  @click="to_course(item)">
						下载 </div>
						
				
					 
  				</div>
			</div>
		</div>
		<div class="u-flex u-row-center u-m-b-60">
			<el-pagination
			  background
			  layout="prev, pager, next"
			   @current-change="currentChange"
			  :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {to_link} from "@/router/api"
	import {getWorldCourse} from "@/api/center.js"
	export default {
		data(){
			return {
				loading:false,
				params:{
					page:1,
					pageNum:9,
					cate_id:'',
				},
				list:[],
				total:0,
				 downloading: false,
				 downloadProgress: 0,
			}
		},
		mounted() {
			console.log(this.$route);
			this.params.cate_id = this.$route.params.typeId
			this.getList()
		},
		methods:{
			to_link,
			currentChange(e){
				this.params.page = 1
				this.getList()
			},
			//课程详情
			to_course(item){
				if(!item.download_url){
					this.$message.error('当前课程暂无下载资源')
					return false
				}
				var that=this;
				var x = new XMLHttpRequest();
				    x.open("GET", item.download_url, true);
				    x.responseType = 'blob';
					that.downloading=true;
					x.onprogress = function(e) {
					    if (e.lengthComputable) {
					        var percentComplete = (e.loaded / e.total) * 100;
					        // 在这里可以更新下载进度条或其他UI元素
							that.downloadProgress=percentComplete.toFixed(2)
					        console.log("下载进度: " + percentComplete.toFixed(2) + "%");
					    }
					};

				    x.onload=function(e) {
						that.downloadProgress=0;
						that.downloading=false;
				        //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
				        var url = window.URL.createObjectURL(x.response)
				        var a = document.createElement('a');
				        a.href = item.download_url
				        a.download = item.title;
				        a.click()
						that.downloading=false; 
				    }
				    x.send();
					
			},
			async getList(){
				this.loading = true
				let res = await getWorldCourse(this.params)
				setTimeout(()=>{
					this.loading = false
				},500)
				if(res.code == 200){
					this.list = res.data
					this.total = res.count ||0
				}else{
					this.$message.error(res.message)
				}
			}
		}
	}
</script>

<style lang="scss" scoped> 
	.m-right-title{
		display: flex;
		height: 64px;
		line-height: 20px;
		padding-top: 32px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		padding-left: 38px;
		border-bottom: 1px solid #EEEEEE;
		box-sizing: border-box;
	}
	.m-list{
		display: flex;
		flex-direction: column;
		padding-top: 20px;
		padding-left: 38px;
		padding-right: 38px;
		padding-bottom: 60px;
		.m-item{
			display: flex;
			height: 90px;
			margin-right: 22px;
			box-sizing: content-box;
			padding: 22px 0;
			cursor: pointer;
			border-bottom: 1px dashed #D1D1D1;
			.m-item-font1{
				font-size: 14px;
				color: #505050;
				line-height: 22px;
			}
			.m-item-img{
				position: relative;
				width: 160px;
				height: 90px;
				display: flex;
				align-items: center;
				overflow: hidden;
				.m-item-tip{
					position: absolute;
					top: 11px;
					left: 0;
					z-index: 1;
					width: 40px;
					height: 20px;
					font-size: 14px;
					color: #fff;
					opacity: 0.5;
					background: #000;
					text-align: center;
					line-height: 20px;
				}
				img{
					width: 100%;
				}
			}
			.m-item-jindu{
				display: flex;
				color: #D6102A;
				font-size: 14px;
			}
			.m-item-con{
				display: flex;
				flex-direction: column;
				width: 480px;
				height: 90px;
				justify-content: space-between;
				
			}
			.m-item-time{
				display: flex;
				align-items: center;
				color: #7C7C7C;
				font-size: 14px;
				line-height: 14px;
			}
			.m-item-btn{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 117px;
				height: 35px;
				background: #F8A310;
				border-radius: 18px;
				font-size: 14px;
				color: #fff;
			}
			.m-item-btn-extend{
				background: #D6102A;
			}
		}
	}
</style>

<style lang="scss">
	.m-item-con{
		.el-progress-bar__inner{
			background-color: #D6102A;
		}
	}
</style>
